import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';

class Start extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, pageContext } = this.props;

    return (
      <>
        <Seo context={pageContext} />
        <Layout></Layout>
      </>
    );
  }
}

export default Start;
